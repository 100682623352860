import { tns } from 'tiny-slider/src/tiny-slider'

const galleries = document.querySelectorAll('[data-js-image-gallery]')

galleries.forEach(gallery => {
  if (gallery.childElementCount > 1) {
    tns({
      nav: false,
      controlsPosition: 'top',
      controlsContainer: gallery.closest('.gallery').querySelector('[data-js-gallery-controls]'),
      container: gallery,
      mouseDrag: true,
      items: 1.17,
      gutter: 16,
      responsive: {
        700: {
          items: 1.5
        },
        922: {
          items: 1.75
        },
        1248: {
          items: 2
        }
      }
    })
  }
})
